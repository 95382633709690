* {
  box-sizing: border-box;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.Container-full {
  max-width: 90%;
}

@media screen and (max-width: 1024px){
  .Container-full { 
    max-width: 100%;
  }
}
.vernum {
  position:absolute;
  bottom:0;
  right:0;
  margin:auto;
  font-size:8px;
  color: #999999;

}

.Col-right {
  max-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

.gray-info {
  color: #999999;
}

.react-pdf__Document {
  position: relative;
}
.react-pdf__Document:hover .page-controls {
  opacity: 1;
}
.page-controls {
  z-index: 1000;
  position: relative;
  top: 0%;
  left: 50%;
  font-family: sans-serif;
  text-align: center;
  background: white;
  border: 1px solid black;
  opacity: 1;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  min-width: 175px;
}
.page-controls span {
  font: inherit;
  font-size: .8em;
  padding: 0 .5em;
}
.page-controls button {
  width: 44px;
  height: 22px;
  background: white;
  border: 0;
  font: inherit;
  font-size: .8em;
}
.page-controls button:enabled:hover {
  cursor: pointer;
}
.page-controls button:enabled:hover, .page-controls button:enabled:focus {
  background-color: #e6e6e6;
}
.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
